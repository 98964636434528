import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

function Profile() {


  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="user-details">
            <h1>My Profile</h1>
            <form className="form-user">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>User Name</label>
                            <input type="text" className="form-control" placeholder="User Name" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Phone Number</label>
                            <input type="number" className="form-control" placeholder="Phone Number" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" className="form-control" placeholder="Email" />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="user-buttons">
                            <button className="edit">Edit</button>
                            {/* <button className="remove">Remove</button> */}
                        </div>
                    </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
