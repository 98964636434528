import React, { useState, useEffect } from "react";
import Image from "../../Assets/Images/Rectangle 3.png";
import Image1 from "../../Assets/Images/Frame.png";
import Image2 from "../../Assets/Images/Frame (1).png";
import Image3 from "../../Assets/Images/Frame (2).png";
import Image4 from "../../Assets/Images/Chart_pin_light.png";
import Url from "../../Api/Api";
import { Link } from "react-router-dom";

function EventCard({ card }) {

  return (
    <>
        <div className="request-card">
            <img src={Image} alt="request-image" className="request-image" />
            <div>
                <h2>{card.title}</h2>
                <p>{card.dis}<Link className="details-event" to={`/EventDetails/${card.id}`}>Read More</Link></p>
                <div className="request-buttons">
                    <div>
                        <button className=""><img src={Image1} alt="text"/>Technology</button>
                        <button className=""><img src={Image2} alt="text" />Technology</button>
                        <button className=""><img src={Image4} alt="text" />Sponsors</button>
                        <button className=""><img src={Image3} alt="text" />Tickets Category</button>
                    </div>
                </div>
            </div>
            <h3><span>24</span><span>May</span><span>9:30pm</span></h3>
        </div>
    </>
  );
}

export default EventCard;
