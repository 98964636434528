import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Table from "../Table/Table";
import axios from "axios";
import Swal from "sweetalert2";
import { axiosConfig } from "../axiosAPI";
import { useAuth } from "../AuthProvider";
import { Bar } from 'react-chartjs-2';
import { PolarArea } from 'react-chartjs-2';  
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(BarElement, RadialLinearScale, ArcElement, CategoryScale, LinearScale, Tooltip, Legend);

function Payment() {
    const [customers, setCustomers] = useState([]);
    const [formData, setFormData] = useState({});
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [loading, setLoading] = useState(false);
    const auth = useAuth();

    // const getData = async () => {
    //   try {
    //     const response = await axiosConfig.get(
    //       "http://dog.addictaco.com/api/v2/dashboard/payments",
    //       {
    //         headers: {
    //           Authorization: "Bearer " + auth.token,
    //           "x-api-key": "aB1cD2eF3gH4iJ5kL6mN7oP8qR9sT0uV",
    //         },
    //       }
    //     );
    //     setCustomers(response.data.data.result);
    //   } catch (error) {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: "Something went wrong!",
    //     });
    //   }
    // };
    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };

      _filters["global"].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
    };
    const renderHeader = () => {
      return (
        <div className="flex justify-content-end">
          <IconField iconPosition="left">
            <i className="fa fa-search"></i>
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </IconField>
        </div>
      );
    };
    const header = renderHeader();
    const onRowEditComplete = (e) => {
      let _customers = [...customers];
      let { newData, index } = e;

      _customers[index] = newData;

      setCustomers(_customers);
    };
    useEffect(() => {
      // getData().then((data) => {
      //   setLoading(false);
      // });
    }, []);

    const data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'January', 'February', 'March', 'April', 'May', 'April', 'May'],
      datasets: [
      {
        label: 'Total Sales',
        data: [12, 19, 3, 5, 2, 12, 19, 3, 5, 2, 5, 2], 
        backgroundColor: '#3A57E8',
        borderColor: '#3A57E8',
        borderWidth: 1,
        borderRadius: 10,
        barThickness: 10, 
      },
      {
        label: 'Net profit',
        data: [8, 15, 6, 7, 4, 8, 15, 6, 7, 4, 7, 4], 
        backgroundColor: '#85F4FA',
        borderColor: '#85F4FA',
        borderWidth: 1,
        borderRadius: 10,
        barThickness: 10, 
      },
    ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: { position: 'top' },
      },
      scales: {
        x: {
          stacked: true, // Enables stacking on x-axis
        },
        y: {
          stacked: true, 
          beginAtZero: true,
        },
      },
    };

    const data2 = {
      labels: ['Red', 'Blue', 'Yellow'], 
      datasets: [
        {
          label: 'Dataset 1',
          data: [11, 16, 7], 
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  
    const options2 = {
      responsive: true,
      plugins: {
        legend: { position: 'top' },
      },
      scales: {
        r: {
          ticks: {
            display: false, // Hides the numbers on the radial axis
          },
          grid: {
            circular: true, // Keeps the circular grid lines
          },
        },
      },
    };
  

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <h1>Payments</h1>
            <div className="table">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="amount-card">
                        <Bar data={data} options={options} />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="amount">
                        <h2>Total Amount</h2>
                        <div className="amount-card">
                          <span>Total Sales</span>
                          <h3>$ 784,00</h3>
                        </div>
                      </div>
                      <div className="amount">
                        <h2>Events</h2>
                        <div className="amount-card">
                          <PolarArea data={data2} options={options2} />;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <DataTable
                    value={customers}
                    editMode="row"
                    paginator
                    showGridlines
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "700px" }}
                    loading={loading}
                    //   dataKey="id"
                    filters={filters}
                    globalFilterFields={["passengerFullName", "driverFullName", "fare", "date", "paymentMethod"]}
                    header={header}
                    emptyMessage="No Payment found."
                    onRowEditComplete={onRowEditComplete}
                  >
                    <Column
                      field="passengerFullName"
                      header="Event Creator"
                      style={{ width: "20%" }}
                    ></Column>
                    <Column
                      field="driverFullName"
                      header="Event Name"
                      style={{ width: "20%" }}
                    ></Column>
                    <Column
                      field="fare"
                      header="Address"
                      style={{ width: "20%" }}
                    ></Column>
                    <Column
                      field="date"
                      header="date"
                      style={{ width: "20%" }}
                    ></Column>
                    <Column
                      field="paymentMethod"
                      header="Subscription"
                      style={{ width: "20%" }}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
