import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useAuth } from "../AuthProvider";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { axiosConfig } from "../axiosAPI";
import FAQs from "./FAQs";
import Contact from "./Privacy";

function Setting() {
  const auth = useAuth();

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="setting">
            <Tabs>
              <TabList>
                <Tab>FAQs</Tab>
                <Tab>Privacy Policy</Tab>
              </TabList>

              <TabPanel>
                <FAQs />
              </TabPanel>
              <TabPanel>
                <Contact />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default Setting;
