import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Url from "./Api/Api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [refresh, setRefresh] = useState(localStorage.getItem("refreshToken") || "");
  const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
  const navigate = useNavigate();
  
  const loginAction = async (data) => {
    try {
      const response = await fetch(
        `${Url}/account/authenticate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "aB1cD2eF3gH4iJ5kL6mN7oP8qR9sT0uV",
          },
          body: JSON.stringify(data),
        }
      );
      const res = await response.json();
      if (res) {
        console.log(res);
        setRefresh(res.data.refreshToken);
        setToken(res.data.accessToken);
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        navigate("/Dashboard");
        return;
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };


  const auth = useAuth();
  const logOut = async (data) => {
    try {
      const response = await fetch(
        `${Url}/account/logout`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "aB1cD2eF3gH4iJ5kL6mN7oP8qR9sT0uV",
          },
        }
      );
      if (response) {
        setRefresh(null);
        setToken("");
        localStorage.clear();
        navigate("/");
        return;
      }
      throw new Error(response.message);
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <AuthContext.Provider value={{ token, refresh, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
