import React, { useState, useEffect } from "react";
import Image from "../../Assets/Images/Bitmap.png";
import Url from "../../Api/Api";
import { Link } from "react-router-dom";

function EventCard({ card }) {

  return (
    <>
        <div className="event-card">
            <div className="event-card-img">
                <img src={Image} alt="event-image" />
            </div>
            <div className="event-card-body">
                <h2>{card.title}</h2>
                <p>{card.dis}<Link className="details-event" to={`/EventDetails/${card.id}`}>Read More</Link></p>
                <div className="event-buttons">
                    <Link to="/EventDetails/" className="details">See Details</Link>
                    <div>
                        <button className="Reject">Reject</button>
                        <button className="Approve me-0">Approve</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default EventCard;
