import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./Assets/Css/Web.css";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Payment from "./Pages/Payment";
import User from "./Pages/User/User";
import AuthProvider from "./AuthProvider";
import PrivateRoute from "./route";
import Setting from "./Pages/Setting";
import Event from "./Pages/Event/Event";
import Analysis from "./Pages/Analysis";
import Support from "./Pages/Support";
import Admins from "./Pages/Admins";
import Roles from "./Pages/Roles";
import UserDetails from "./Pages/User/UserDetails";
import Profile from "./Pages/Profile";
import Request from "./Pages/Reuest/Reuest";

function App() {
  

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/Payment" element={<Payment />} />
              <Route exact path="/Profile" element={<Profile />} />
              <Route exact path="/Users" element={<User />} />
              <Route exact path="/UserDetails/:id" element={<UserDetails />} />
              <Route exact path="/Events" element={<Event />} />
              <Route exact path="/Request" element={<Request />} />
              <Route exact path="/Analysis" element={<Analysis />} />
              <Route exact path="/Support" element={<Support />} />
              <Route exact path="/Admins" element={<Admins />} />
              <Route exact path="/Roles" element={<Roles />} />
              <Route exact path="/Setting" element={<Setting />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}


export default App;
