import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import MyInput from "../Charts/Month";
import DonutChart from "../Charts/Option";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";
import MOnth from "../Charts/Month";
import ChartComponent from "../Charts/Month";
import Month from "../Charts/Month";
import Option from "../Charts/Option";

function Support() {

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="support">
            <h1>Customer Support</h1>
            <div className="support-card">
              <div className="row">
                <div className="col-md-5">
                  <h2>Monster Inc.</h2>
                  <p>Monsterinc@boo.com +2365987423</p>
                  <span>Created: 18/12/2024</span>
                  <div className="support-button">
                    <button className="technical">Technical</button>
                    <button className="clint"><i className="fa fa-user"></i>Contact with Clint</button>
                  </div>
                </div>
                <div className="col-md-7">
                  <div>
                    <h3>Children to scream</h3>
                    <strong>Description</strong>
                    <p>Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consecteturLorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consecteturLorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="support-card">
              <div className="row">
                <div className="col-md-5">
                  <h2>Monster Inc.</h2>
                  <p>Monsterinc@boo.com +2365987423</p>
                  <span>Created: 18/12/2024</span>
                  <div className="support-button">
                    <button className="technical">Technical</button>
                    <button className="clint"><i className="fa fa-user"></i>Contact with Clint</button>
                  </div>
                </div>
                <div className="col-md-7">
                  <div>
                    <h3>Children to scream</h3>
                    <strong>Description</strong>
                    <p>Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consecteturLorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consecteturLorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;
