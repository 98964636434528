import React, { useState, useEffect } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import Navbar from "../../Navbar/Navbar";
import { axiosConfig } from "../../axiosAPI";
import Url from "../../Api/Api";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import EventCard from "./EventCard";

function Event() {

  const cards = [
    { id: 1, title: "VR future", dis: "Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur.", img: "../../Assets/Images/Bitmap.png", status: "Pending" },
    { id: 2, title: "Open Movie Discussion", dis: "Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur.", img: "../../Assets/Images/Bitmap.png", status: "In progress" },
    { id: 3, title: "Open Movie Discussion", dis: "Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur.", img: "../../Assets/Images/Bitmap.png", status: "Pending" },
    { id: 4, title: "Open Movie Discussion", dis: "Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur.", img: "../../Assets/Images/Bitmap.png", status: "Ended" },
    { id: 5, title: "Open Movie Discussion", dis: "Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur.", img: "../../Assets/Images/Bitmap.png", status: "Pending" },
    { id: 6, title: "Open Movie Discussion", dis: "Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur.", img: "../../Assets/Images/Bitmap.png", status: "In progress" },
    { id: 7, title: "Open Movie Discussion", dis: "Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur.", img: "../../Assets/Images/Bitmap.png", status: "Ended" },
    { id: 8, title: "Open Movie Discussion", dis: "Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur.", img: "../../Assets/Images/Bitmap.png", status: "Ended" },
  ];

  const [activeFilter, setActiveFilter] = useState("All");
  const [direction, setDirection] = useState("right"); // Track animation direction

  const directions = {
    All: "right",
    Pending: "right",
    "In progress": "left",
    Created: "up",
    Ended: "down",
  };

  const filteredCards = activeFilter === "All" ? cards : cards.filter(card => card.status === activeFilter);

  const handleFilterChange = (filter) => {
    setDirection(directions[filter]); // Update direction based on the filter
    setActiveFilter(filter); // Update active filter
  };

  const variants = {
    enter: (direction) => ({
      x: direction === "right" ? 300 : direction === "left" ? -300 : 0,
      y: direction === "down" ? 300 : direction === "up" ? -300 : 0,
      opacity: 0,
    }),
    center: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 },
    },
    exit: (direction) => ({
      x: direction === "right" ? -300 : direction === "left" ? 300 : 0,
      y: direction === "down" ? -300 : direction === "up" ? 300 : 0,
      opacity: 0,
      transition: { duration: 0.5 },
    }),
  };


  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="event">

            <div className="filter-buttons">
              {["All", "In progress", "Created",   "Pending", "Ended"].map((filter, index) => (
                <button
                  key={index}
                  onClick={() => handleFilterChange(filter)}
                  className={`filter-button ${activeFilter === filter ? "active" : ""}`}
                >
                  {filter}
                </button>
              ))}
            </div>

            <h1>Events</h1>
            <div className="row">
              <AnimatePresence>
                {filteredCards.length > 0 ? (
                  filteredCards.map((card) => (
                          <motion.div
                            key={card.id}
                            className="col-md-12"
                            variants={variants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            custom={direction}
                          >
                            <EventCard card={card} />
                          </motion.div>
                  ))
                ) : (
                  <p className="no-cards">No Events found for this category.</p>
                )}
              </AnimatePresence>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Event;
