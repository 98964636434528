import React, { useState, useEffect } from "react";
import "../Assets/Css/login.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { useForm } from "react-hook-form";

function Login() {
  // const [phoneOrEmail, setphoneOrEmail] = useState();
  // const [password, setPassword] = useState();

  // const auth = useAuth();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (phoneOrEmail !== "" && password !== "") {
  //     auth.loginAction({
  //         phoneOrEmail: phoneOrEmail,
  //         password: password
  //     });
  //     return;
  //   }
  // };

  const [step, setStep] = useState(1);
  const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm();
  
  const [otpVerified, setOtpVerified] = useState(false);

  const onSubmitStep1 = (data) => {
    setStep(2);
  };

  const onSubmitStep2 = (data) => {
    const { otp } = data;
    if (otp === "123456") { // Simulating OTP verification
      setOtpVerified(true);
      setStep(3);
    } else {
      alert("Invalid OTP. Try again.");
    }
  };


  return (
    <div className="login">
      <div className="row h-100">
        <div className="col-md-6">
          <div className="login-left">
            <p>Logo</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="login-card">
            <div className="step-form-container">
              <div className="step-header">
                <div className={`step-item ${step >= 1 ? "active" : ""}`}>
                  <div className="step-number">1</div>
                  {/* <span>Registration</span> */}
                </div>
                <div className={`step-line ${step >= 2 ? "active" : ""}`}></div>
                <div className={`step-item ${step >= 2 ? "active" : ""}`}>
                  <div className="step-number">2</div>
                  {/* <span>OTP</span> */}
                </div>
                <div className={`step-line ${step === 3 ? "active" : ""}`}></div>
                <div className={`step-item ${step === 3 ? "active" : ""}`}>
                  <div className="step-number">3</div>
                  {/* <span>Success</span> */}
                </div>
              </div>
              {step === 1 && (
                <form onSubmit={handleSubmit(onSubmitStep1)}>
                  <div>
                    <h2>Step 1: User Registration</h2>
                    <p></p>
                  </div>
                  <input
                    {...register("username", { required: "Username is required" })}
                    placeholder="Username"
                  />
                  {errors.username && <span>{errors.username.message}</span>}

                  <input
                    {...register("email", {
                      required: "Email is required",
                      pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email format" }
                    })}
                    placeholder="Email"
                  />
                  {errors.email && <span>{errors.email.message}</span>}

                  <input
                    type="password"
                    {...register("password", { required: "Password is required" })}
                    placeholder="Password"
                  />
                  {errors.password && <span>{errors.password.message}</span>}

                  <input
                    type="password"
                    {...register("confirmPassword", {
                      required: "Confirm Password is required",
                      validate: (value) =>
                        value === watch("password") || "Passwords do not match"
                    })}
                    placeholder="Confirm Password"
                  />
                  {errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}

                  <button type="submit">Next</button>
                </form>
              )}

              {step === 2 && (
                <form onSubmit={handleSubmit(onSubmitStep2)}>
                  <h2>Step 2: OTP Verification</h2>
                  <input
                    {...register("otp", {
                      required: "OTP is required",
                      pattern: { value: /^\d{6}$/, message: "OTP must be 6 digits" }
                    })}
                    placeholder="Enter 6-digit OTP"
                  />
                  {errors.otp && <span>{errors.otp.message}</span>}

                  <button type="submit">Verify OTP</button>
                </form>
              )}

              {step === 3 && otpVerified && (
                <div>
                  <h2>Success!</h2>
                  <img src="https://via.placeholder.com/150" alt="Success" />
                  <p>Your account has been successfully created.</p>
                  <button onClick={() => { setStep(1); reset(); }}>Restart</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
