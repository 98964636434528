import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { axiosConfig } from "../axiosAPI";
import { Alert, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { Editor } from "primereact/editor";

function Contact() {


  return (
    <>
        <div className="table mt-5">
            <div className="text-editor">
                <h2>About Us</h2>
                <Editor
                name="about_En"
                style={{ height: "320px" }}
                />
            </div>
            <div className="text-editor">
                <h2>Terms and Conditions</h2>
                <Editor
                name="termsAndConditions_En"
                style={{ height: "320px" }}
                />
            </div>
            <div className="text-editor">
                <h2>Privacy Policy</h2>
                <Editor
                name="privacyPolicy_En"
                style={{ height: "320px" }}
                />
            </div>
            <div className="">
                <a
                className="save"
                onClick={(e) => {
                //   editUser()
                }}
                >
                Save
                </a>
            </div>
        </div>
    </>
  );
}

export default Contact;