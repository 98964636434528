import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(LineElement, CategoryScale, ArcElement, LinearScale, PointElement, Tooltip, Legend);

function Analysis() {
  

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'January', 'February', 'March', 'April', 'May', 'April', 'May'],
    datasets: [
    {
      label: 'Total Sales',
      data: [12, 19, 3, 5, 2, 12, 19, 3, 5, 2, 5, 2], 
      backgroundColor: '#3A57E8',
      borderColor: '#3A57E8',
      borderWidth: 1,
      borderRadius: 10,
      barThickness: 10, 
      tension: 0.4, // Line smoothing (optional)
    },
    {
      label: 'Net profit',
      data: [8, 15, 6, 7, 4, 8, 15, 6, 7, 4, 7, 4], 
      backgroundColor: '#85F4FA',
      borderColor: '#85F4FA',
      borderWidth: 1,
      borderRadius: 10,
      barThickness: 10, 
      tension: 0.4, // Line smoothing (optional)
    },
  ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' }, // Legend position
    },
    scales: {
      x: {
        beginAtZero: true, // Start x-axis at zero
      },
      y: {
        beginAtZero: true, // Start y-axis at zero
      },
    },
  };

  const data2 = {
    labels: ['Red', 'Blue', 'Yellow', 'Green'], // Segment labels
    datasets: [
      {
        label: 'Dataset 1', // Dataset label
        data: [300, 50, 100, 75], // Dataset values
        backgroundColor: ['#FF5733', '#33C1FF', '#FFFF66', '#33FF57'], // Segment colors
        hoverBackgroundColor: ['#FF5733', '#33C1FF', '#FFFF66', '#33FF57'], // Hover segment colors
      },
    ],
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top', // Position of the legend
      },
      tooltip: {
        enabled: true, // Tooltip visibility
      },
    },
  };

  const data3 = {
    labels: ['Red', 'Blue', 'Yellow', 'Green'], // Segment labels
    datasets: [
      {
        label: 'Dataset 1', // Dataset label
        data: [300, 50, 100, 75], // Dataset values
        backgroundColor: ['#FF5733', '#33C1FF', '#FFFF66', '#33FF57'], // Segment colors
        hoverBackgroundColor: ['#FF5733', '#33C1FF', '#FFFF66', '#33FF57'], // Hover segment colors
      }
    ],
  };

  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top', // Position of the legend
      },
      tooltip: {
        enabled: true, // Tooltip visibility
      },
    },
  };

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="charts-card">
            <h1>Data & Analysis</h1>
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="amount-card">
                  <h2>Revenue</h2>
                    <Line data={data} options={options} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="amount-card">
                  <h2>Customers</h2>
                  <Doughnut data={data2} options={options2} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="amount-card">
                  <h2>Tickets</h2>
                  <Pie data={data3} options={options3} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="amount-card">
                  <h2>Sales Analytics</h2>
                  <Line data={data} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Analysis;
