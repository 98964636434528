import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import MyInput from "../Charts/Month";
import DonutChart from "../Charts/Option";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";
import MOnth from "../Charts/Month";
import ChartComponent from "../Charts/Month";
import Month from "../Charts/Month";
import Option from "../Charts/Option";

function Roles() {

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="charts-card">

          </div>
        </div>
      </div>
    </>
  );
}

export default Roles;
