import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Link } from "react-router-dom";
import EventCard from "./Event/EventCard";

ChartJS.register(LineElement, CategoryScale, ArcElement, LinearScale, PointElement, Tooltip, Legend);

function Dashboard() {

  
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'January', 'February', 'March', 'April', 'May', 'April', 'May'],
    datasets: [
    {
      label: 'Net profit',
      data: [8, 15, 6, 7, 4, 8, 15, 6, 7, 4, 7, 4], 
      backgroundColor: '#85F4FA',
      borderColor: '#85F4FA',
      borderWidth: 1,
      borderRadius: 10,
      barThickness: 10, 
      tension: 0.4, // Line smoothing (optional)
    },
  ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' }, // Legend position
    },
    scales: {
      x: {
        beginAtZero: true, // Start x-axis at zero
      },
      y: {
        beginAtZero: true, // Start y-axis at zero
      },
    },
  };

  const cards = [
    { id: 1, title: "VR future", dis: "Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur.", img: "../../Assets/Images/Bitmap.png", status: "Pending" },
    { id: 2, title: "Open Movie Discussion", dis: "Lorem ipsum dolor sit amet consectetur. Vitae volutpat amet est tellus proin sen nullam.olor sit amet consectetur. Vitae volutpat amet est tellus Lorem ipsum dolor sit amet consectetur.", img: "../../Assets/Images/Bitmap.png", status: "In progress" },
  ];



  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="charts-card">
            <h1>Dashboard</h1>
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="amount-card">
                  <h2>Subscribers</h2>
                  <Bar data={data} options={options} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="event-dashboard">
                  <h2>Events</h2>
                  <Link to="/Events">See All</Link>
                </div>
                {cards.length > 0 ? (
                  cards.map((card) => (
                    <EventCard card={card} />
                  ))
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
